import { lazy } from "react"
import { createBrowserRouter, redirect } from "react-router-dom"

const Admin = lazy(() => import("@Pages/Admin"))
const External = lazy(() => import("@Pages/External"))
const Internal = lazy(() => import("@Pages/Internal"))
const Login = lazy(() => import("@Pages/Login"))
const Main = lazy(() => import("@Pages/Main"))

const navigator = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
    loader: () => {
      const token = localStorage.getItem("token")
      const user = JSON.parse(localStorage.getItem("user"))
      if (token) {
        if (user.role === "ADMIN") {
          return redirect('/super')
        }
        return null
      } else {
        return redirect('/sign-in')
      }
    }
  },
  {
    path: "/super",
    element: <Admin/>,
    loader: () => {
      const token = localStorage.getItem("token")
      const user = JSON.parse(localStorage.getItem("user"))
      if (token) {
        if (user.role === "REFERRER") {
          return redirect('/')
        }
        return null
      } else {
        return redirect('/sign-in')
      }
    }
  },
  {
    path: "/klien",
    element: <External/>,
  },
  {
    path: "/klien/:id",
    element: <External/>
  },
  {
    path: "/tko",
    element: <Internal/>
  },
  {
    path: "/tko/:id",
    element: <Internal/>
  },
  {
    path: "/sign-in",
    element: <Login/>
  }
])

export default navigator